import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/SEO'

const post = {
  title: "Conditions générales de vente - Arti'mouss",
  description: 'Voici le bla bla fait par des avocats pour des avocats, en cas de question envoyez moi un email !',
  image: 'https://archimouss.ch/assets/images/logo/big.png'
}


const CGV = () => {
  return (
    <>
    <Seo title={post.title} description={post.description} image={post.image}/>
   
    <Layout>
        <div className='main'>
            <div className='main-content'>
                <div className='mini-content'>

                    <h1>Conditions générales de vente</h1>
                    <h2>Objet</h2>
                    <p> Les présentes Conditions Générales de Vente régissent les droits et obligations des parties dans le cadre des ventes en ligne sur le site internet artimouss.ch.
                    </p>
                    <h2>Principe général</h2>
                    <p> Tout achat d’un article sur le site internet artimouss.ch vaut acceptation des présentes Conditions Générales de Vente par l’Acheteur. <br/><br/>Les Conditions Générales de Vente sont accessibles sur le site internet artimouss.ch.
                    </p>
                    <h2>Confirmation et conclusion du contrat</h2>
                    <p>Tout achat effectué avec succès fait l’objet d’un message de confirmation auprès de l’Acheteur et d’un courrier électronique de confirmation de commande.
                    <br/><br/>
                    Le contrat de vente est réputé conclu au moment de la confirmation de commande par le Vendeur qui intervient après validation de la commande et réception du prix de vente.
                    <br/><br/>
                    En passant commande, l’Acheteur déclare jouir de l’exercice des droits civils.
                    </p>
                    <h2>Obligations de l’Acheteur</h2>
                    <p> L’Acheteur s’engage à fournir des coordonnées valides lors de la commande et à payer le prix demandé.
                    <br/><br/>
                    Il n’y a pas de restriction de domiciliation pour passer commande d’un produit proposé à la vente sur le site internet artimouss.ch, cependant il revient à l’acheteur de se renseigner auprès des douanes si la législation autorise l’importation dans le pays de destination. 
                    <br/><br/>
                    Le Vendeur n’assume aucune responsabilité du fait qu’une marchandise commandée ne puisse plus être livrée faute de stock disponible, d’approvisionnement supplémentaire, de production ou de saisie douanière.
                    <br/><br/>
                    Le Vendeur demeure libre de supprimer ou de modifier l’offre des produits proposés, à sa seule discrétion et en tout temps.
                    </p>
                    <h2>Prix</h2>
                    <p> Les prix de ce catalogue sont garantis nets, TVA comprise, établis en franc suisse.
                    <br/><br/>
                     Les prix indiqués ne tiennent pas compte des frais d’expédition, facturés en supplément et variables selon le poids et la taille du colis.
                     <br/><br/>
                     Les frais de livraison pour toutes livraisons sont indiqués dans le résumé de la commande.
                     <br/><br/>
                     Une fois la commande validée, le prix de celle-ci ne pourra plus être modifié.
                     <br/><br/>
                     Les articles bénéficiant d'une garantie sont soumis aux conditions de l'offre valable lors de l'achat. La garantie prend effet à la date de livraison de l'article, justifiée par le document d'accompagnement (facture) que nous vous demandons de conserver soigneusement. <br/><br/>Ne sont pas couverts : les dommages dus à un mauvais entretien, au non-respect du mode d'emploi ou à une usure normale.
                     <br/><br/>
                     Sous réserve de ce qui précède, le Vendeur est autorisé à modifier, à sa seule discrétion et en tout temps, le prix des marchandises proposées.
                    </p>
                    <h2>Expédition des produits et délais</h2>
                    <p> L'envoi des articles disponibles est effectué par poste dans un délai de 1 à 3 jours ouvrés sauf cas particuliers. <br/><br/>Une participation de votre part peut être demandée une seule fois par commande.
                    <br/><br/>
                    Nous n'étudions pas les lois en vigueur dans d'autres pays que la Suisse et la société ne peut en aucun cas être tenue pour responsable si le colis ne parvient pas à destination pour cette raison.
                    <br/><br/>
                     IMPORTANT : Little Yes sàrl ne livre pas ses colis en case postale et ne livre aucune marchandise à une clientèle mineure ou sous tutelle, sauf autorisation écrite des parents ou du tuteur.
                     <br/><br/>
                     Le transfert des risques - qui comprend le risque de perte ainsi que la dégradation de la marchandise - passe à l’Acheteur au moment de la confirmation de la commande par Vendeur.
                     <br/><br/>
                     Toute erreur d'adressage ou colis qui nous viennent en retour car non réclamés et dont nous devons renvoyer la marchandise une 2e fois seront facturés avec un forfait de Chf 20.- comprenant les frais d'un 2e envoi ainsi que les frais administratifs y relatifs.
                    </p>
                    <h2>Droits de douane</h2>
                    <p> Lorsque l'Acheteur commande des produits devant être livrés dans un pays autre que la Suisse, l'Acheteur est considéré comme l'importateur agréé et devra, à ce titre, respecter les lois et réglementations en vigueur dans le pays dans lequel il réceptionnera la marchandise.
                    <br/><br/>
                     L'Acheteur peut être assujetti à des droits ou taxes d'importation à l'arrivée de la marchandise dans son pays. Tous les frais additionnels de dédouanement sont à sa charge. Nous n'avons aucun contrôle sur ces frais et ne connaissons pas leur montant. Les politiques douanières variant d'un pays à l'autre, l'Acheteur doit contacter son service local des douanes pour de plus amples informations.
                    </p>
                    <h2>Retour ou échange de marchandises</h2>
                    <p> Les articles doivent être renvoyés intacts et complets dans leur emballage d'origine (avec les étiquettes comportant leurs références), accompagnés du bordereau de livraison, dans un délai de 7 jours après réception de votre colis, date du timbre-poste faisant foi. <br/><br/>Tout paquet ouvert ou endommagé ne sera pas repris. Les frais de port liés au retour sont à votre charge. <br/><br/>Dans le cas d'une commande échange, les frais de port de votre nouvelle commande vous sont offerts.
                    </p>
                    <h2>Paiement</h2>
                    <p> Méthodes de paiement : visa, mastercard, carte bancaire, american express, Apple pay et Google pay.
                    </p>
                    <h2>Garantie</h2>
                    <p> L’état des marchandises livrées est à vérifier dans les 2 jours ouvrables dès réception pour exclure des vices de matière et de fabrication manifestes, ainsi que des dommages dus au transport. <br/><br/>Conformément à la loi, vous êtes dans l’obligation de nous signaler tout défaut ou anomalie affectant les marchandises livrées pour nous permettre d’y remédier.
                    </p>
                    <h2>Modification des conditions générales de vente</h2>
                    <p> Le Vendeur est susceptible de modifier les présentes Conditions Générales de vente.<br/><br/> La version en vigueur au moment de la commande fait foi dans la relation contractuelle entre l’Acheteur et le Vendeur.
                    </p>
                    <h2>Droit applicable et for</h2>
                    <p>En cas de litige, les Conditions Générales de vente en vigueur au moment de l’achat sont utilisées comme base juridique.
                    <br/><br/>
                    Le droit applicable est le droit Suisse et tout litige sera traité par le Tribunal de Genève.</p>
                </div>
            </div>
        </div>
        
    </Layout>
    </>
  )
}

export default CGV